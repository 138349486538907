<script>
import { storeForms } from '@app/mixins/forms';
import TiptapEditor from '@shared/components/TiptapEditor.vue';

export default {
  mixins: [storeForms],
  components: {
    TiptapEditor,
  },
  data() {
    return {
      store: {
        community_options: {
          description: '',
        },
      },
    };
  },
  methods: {
    onSave() {
      this.handle(() => this.$emit('done'));
    },
  },
};
</script>

<template>
  <form @submit.prevent="dataIsValid(isLoading) && onSave()">
    <b-field label="Rédigez ici une présentation de votre communauté. Cette page sera la première que vos apprenants verront en arrivant sur la communauté. Profitez de ce texte pour accueillir vos apprenants, annoncer vos nouveautés...">
      <TiptapEditor
        :value="store.community_options.description"
        @input="store.community_options.description = $event"
      />
    </b-field>
    <div class="field">
      <b-button type="is-primary" native-type="submit">
        Mettre à jour
      </b-button>
    </div>
  </form>
</template>
